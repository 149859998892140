/* eslint-disable import/prefer-default-export */
import { FC, ReactNode, useCallback, useState } from 'react';

import {
  Button,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-default
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';

import { TrackingParams, useTracking } from '@/context/TrackingContext';

export type LinkProps = {
  children: JSX.Element | ReactNode | string;
} & ChakraLinkProps &
  NextLinkProps & {
    tracking?: TrackingParams;
  };

export const Link: FC<LinkProps> = ({
  tracking,
  onClick,
  ...restProps
}: LinkProps) => {
  const { trackEvent } = useTracking();
  const handleClick = useCallback(
    (e) => {
      if (tracking) {
        trackEvent(tracking);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, trackEvent, tracking]
  );

  return <ChakraLink as={NextLink} onClick={handleClick} {...restProps} />;
};

const ShareableLink = ({ url }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        setCopySuccess('Link copied!');
        setTimeout(() => setCopySuccess(''), 2000);
      },
      () => {
        setCopySuccess('Failed to copy link');
      }
    );
  };

  return (
    <>
      <Button onClick={copyToClipboard}>Share this url</Button>
      {copySuccess && <>{copySuccess}</>}
    </>
  );
};

export default ShareableLink;
