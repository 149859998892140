import { BoxProps } from '@chakra-ui/react';

import SectionColumn from './SectionColumn';
import SectionContent from './SectionContent';
import SectionRow from './SectionRow';

export default function Section(props: BoxProps) {
  const { children, ...rest } = props;
  return (
    <SectionRow {...rest}>
      <SectionColumn>
        <SectionContent>{children}</SectionContent>
      </SectionColumn>
    </SectionRow>
  );
}
