/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FC, useCallback } from 'react';

import {
  Identify,
  identify,
  init,
  reset,
  setUserId as ampSetUserId,
  track,
} from '@amplitude/analytics-browser';

import Config from '@/config';
import { TrackingEvent, TrackingFunnel } from '@/enums/tracking';

type UserProperties = {
  userId?: number;
};

export type TrackingParams =
  | {
      event: TrackingEvent.USER_VISIT_HOME_PAGE;
      amplitudeData: {
        funnel: TrackingFunnel.ONBOARDING;
        utmId?: string;
        utmSource?: string;
        utmMedium?: string;
        utmCampaign?: string;
        utmTerm?: string;
        utmContent?: string;
      };
    }
  | {
      event: TrackingEvent.CTA_CLICKED;
      amplitudeData: {
        ctaLabel: string;
      };
    }
  | {
      event: TrackingEvent.USER_REGISTERED;
      amplitudeData: {
        funnel: TrackingFunnel.ONBOARDING;
        userId: number;
      };
    };

type TrackingContext = {
  trackEvent: (config: TrackingParams) => Promise<void>;
  setUserProperties: (properties: UserProperties) => void;
  setUserId: (userId: number) => void;
  ampReset: () => void;
};

let isAmplitudeEnabled = false;
if (Config.amplitudeApiKey) {
  init(Config.amplitudeApiKey, null, { minIdLength: 1 });
  isAmplitudeEnabled = true;
}

// eslint-disable-next-line no-redeclare
const TrackingContext = React.createContext<TrackingContext>({
  trackEvent: () => Promise.resolve(),
  setUserProperties: () => undefined,
  setUserId: () => undefined,
  ampReset: () => undefined,
});

const TrackingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const trackEvent = useCallback(async (options: TrackingParams) => {
    const trackingParams = options as any;

    // Amplitude
    if (isAmplitudeEnabled && trackingParams.amplitudeData) {
      await track(options.event, (options as any).amplitudeData);
    }

    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({ event: options.event });
    }
  }, []);

  const setUserProperties = useCallback((params: UserProperties) => {
    if (isAmplitudeEnabled) {
      const identity = new Identify();

      Object.keys(params).forEach((key) => {
        if (params[key] !== null && params[key] !== undefined) {
          identity.set(key, params[key]);
        }
      });

      identify(identity);
    }
  }, []);

  const setUserId = useCallback((userId: any) => {
    if (isAmplitudeEnabled) {
      ampSetUserId(String(userId));
    }
  }, []);

  const ampReset = () => {
    if (isAmplitudeEnabled) {
      reset();
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        trackEvent,
        setUserProperties,
        setUserId,
        ampReset,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): TrackingContext {
  const context = React.useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
}

export { TrackingProvider, useTracking };
