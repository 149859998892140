import React from 'react';

import { Section } from '@/components/Layout';
import Page from '@/components/Pages/Page';
import { H1, Link } from '@/elements';
import { Center } from '@chakra-ui/react';

const Home = (): JSX.Element => {
  return (
    <Page>
      <Section>
        <H1>Thought&Function Brochure Site Boilerplate</H1>
      </Section>
      <Section>
        <Center>
          <Link fontSize='xl' href='/formsy'>
            Formsy
          </Link>
        </Center>
      </Section>
    </Page>
  );
};

export default Home;
